// import Http from './http'; // não precisa de .js
import axios from 'axios'

const token = !localStorage.getItem('token')?false:localStorage.getItem('token')
if(token)
  axios.defaults.headers.get['Authorization'] = 'Bearer ' + token

const config = {
  headers: {
    'Authorization': 'Bearer ' + token, 
    'Access-Control-Allow-Origin':'*'
  }
}

export const enviarEmail = (data) => axios.post('/enviarEmail', data, config);