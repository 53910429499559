<template>
  <div style="background-color: #00A8BB; height: 850px;">
    <div id="app">
      <ul id="menu" style="background-color:#000"></ul>
      <v-container grid-list-md fill-height style="padding:3%;">
        <v-layout row wrap>
          <v-flex xs12 md6 style="text-align: center">
            <div class="direita">
              <a
                href="http://enercred.com.br"
                target="_blank"
                alt="Enercred - Energia Limpa e Barata"
              >
                <img
                  src="@/assets/logo-enercred-branco.png"
                  width="250px"
                  style="padding: 5px"
                  alt="Enercred - Energia Limpa e Barata"
                  class="src"
                />
              </a>
              <div class="header hidden-sm-and-down">
                <h1 class="display-3 font-weight-black">Quase lá!</h1>
                <br />
                <div
                  class="title font-italic"
                >Só mais alguns detalhes até finalizarmos seu cadastro!</div>
              </div>
              <div class="header hidden-md-and-up">
                <h1 class="display-1 font-weight-black">Quase lá!</h1>
                <br />
              </div>
            </div>
          </v-flex>
          <v-flex d-flex xs12 md6 style="text-align: center">
            <div class="form_cadastro">
              <div v-if="!processing" style="padding-bottom: 15px;">
                <div class="display-1" style="padding-bottom: 25px; color: #808080">DADOS CAPTURADOS</div>Confirme abaixo os dados da conta.
              </div>
              <v-form v-if="!processing" ref="form" v-model="valid" lazy-validation>
                <v-flex xs12>
                  <v-text-field v-model="this.dados.nome" label="Nome" name="nome" required></v-text-field>
                </v-flex>

                <v-flex xs12>
                  <v-text-field
                    v-model="this.dados.endereco"
                    label="Endereço"
                    name="endereco"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs6>
                  <v-text-field v-model="this.dados.cpf" label="CPF" name="cpf" required></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="this.dados.classeInstalacao"
                    label="Classe"
                    name="classeInstalacao"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs6>
                  <v-text-field
                    v-model="this.dados.numeroCliente"
                    label="Número do Cliente"
                    name="numeroCliente"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="this.dados.numeroInstalacao"
                    label="Número da Instalação"
                    name="numeroInstalacao"
                    required
                  ></v-text-field>
                </v-flex>

                <div style="padding-bottom: 15px;">Consumo Mensal</div>
                <!--
                    <v-flex xs2 md1 v-for="mes in 11" :key="mes">                            
                        <v-text-field
                            v-model="this.dados.consumoMensal[mes]"
                            :label="'Mês ' + mes" 
                            :name="'consumoMensal' + mes" 
                            required
                        ></v-text-field>
                    </v-flex>
                -->
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoMensal[0]"
                    label="Mês 1"
                    name="consumoMensal0"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoMensal[1]"
                    label="Mês 2"
                    name="consumoMensal1"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoMensal[2]"
                    label="Mês 3"
                    name="consumoMensal2"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoMensal[3]"
                    label="Mês 4"
                    name="consumoMensal3"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoMensal[4]"
                    label="Mês 5"
                    name="consumoMensal4"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoMensal[5]"
                    label="Mês 6"
                    name="consumoMensal5"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoMensal[6]"
                    label="Mês 7"
                    name="consumoMensal6"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoMensal[7]"
                    label="Mês 8"
                    name="consumoMensal7"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoMensal[8]"
                    label="Mês 9"
                    name="consumoMensal8"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoMensal[9]"
                    label="Mês 10"
                    name="consumoMensal9"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoMensal[10]"
                    label="Mês 11"
                    name="consumoMensal10"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoMensal[11]"
                    label="Mês 12"
                    name="consumoMensal11"
                    required
                  ></v-text-field>
                </v-flex>

                <div style="padding-bottom: 15px;">Consumo Diário</div>

                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoDiario[0]"
                    label="Mês 1"
                    name="consumoDiario0"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoDiario[1]"
                    label="Mês 2"
                    name="consumoDiario1"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoDiario[2]"
                    label="Mês 3"
                    name="consumoDiario2"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoDiario[3]"
                    label="Mês 4"
                    name="consumoDiario3"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoDiario[4]"
                    label="Mês 5"
                    name="consumoDiario4"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoDiario[5]"
                    label="Mês 6"
                    name="consumoDiario5"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoDiario[6]"
                    label="Mês 7"
                    name="consumoDiario6"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoDiario[7]"
                    label="Mês 8"
                    name="consumoDiario7"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoDiario[8]"
                    label="Mês 9"
                    name="consumoDiario8"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoDiario[9]"
                    label="Mês 10"
                    name="consumoDiario9"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoDiario[10]"
                    label="Mês 11"
                    name="consumoDiario10"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.consumoDiario[11]"
                    label="Mês 12"
                    name="consumoDiario11"
                    required
                  ></v-text-field>
                </v-flex>

                <div style="padding-bottom: 15px;">Dias Contabilizados</div>

                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.quantidadeDias[0]"
                    label="Mês 1"
                    name="cquantidadeDias0"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.quantidadeDias[1]"
                    label="Mês 2"
                    name="quantidadeDias1"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.quantidadeDias[2]"
                    label="Mês 3"
                    name="quantidadeDias2"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.quantidadeDias[3]"
                    label="Mês 4"
                    name="quantidadeDias3"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.quantidadeDias[4]"
                    label="Mês 5"
                    name="quantidadeDias4"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.quantidadeDias[5]"
                    label="Mês 6"
                    name="quantidadeDias5"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.quantidadeDias[6]"
                    label="Mês 7"
                    name="quantidadeDias6"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.quantidadeDias[7]"
                    label="Mês 8"
                    name="quantidadeDias7"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.quantidadeDias[8]"
                    label="Mês 9"
                    name="quantidadeDias8"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.quantidadeDias[9]"
                    label="Mês 10"
                    name="quantidadeDias9"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.quantidadeDias[10]"
                    label="Mês 11"
                    name="quantidadeDias10"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                  <v-text-field
                    v-model="this.dados.quantidadeDias[11]"
                    label="Mês 12"
                    name="quantidadeDias11"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm12 md12 style="text-align: right">
                  <v-btn
                    :disabled="!valid"
                    color="success"
                    @click="validate"
                    flat
                    large
                    type="submit"
                  >CONTINUAR</v-btn>
                </v-flex>
              </v-form>
              <v-progress-circular v-if="processing" :size="50" color="teal" indeterminate></v-progress-circular>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>
<script>
import { addLead } from "@/services/leads";
import { enviarEmail } from "@/services/sendmail";

export default {
  data() {
    return {
      valid: false,
      interval: {},
      progress: 0,
      processing: false,
      dados: {
        classeInstalacao: "",
        consumoDiario: [],
        consumoMensal: [],
        cpf: "",
        endereco: "",
        nome: "",
        numeroCliente: "",
        numeroInstalacao: "",
        quantidadeDias: []
      }
    };
  },
  beforeDestroy() {
    this.processing = false;
  },
  created() {
    if (this.$route.params.formulario) {
      this.dados.classeInstalacao = this.$route.params.formulario.classeInstalacao;
      this.dados.consumoDiario = this.$route.params.formulario.consumoDiario.split(
        " "
      );
      this.dados.consumoMensal = this.$route.params.formulario.consumoMensal.split(
        " "
      );
      this.dados.cpf = this.$route.params.formulario.cpf;
      this.dados.endereco = this.$route.params.formulario.endereco;
      this.dados.nome = this.$route.params.formulario.nome;
      this.dados.numeroCliente = this.$route.params.formulario.numeroCliente;
      this.dados.numeroInstalacao = this.$route.params.formulario.numeroInstalacao;
      this.dados.quantidadeDias = this.$route.params.formulario.quantidadeDias.split(
        " "
      );
      localStorage.setItem("dados", JSON.stringify(this.dados));
      // console.log("Dados na pagina: ", JSON.stringify(this.dados));
    }
  },
  mounted() {
    if (this.dados.cpf === "") {
      if (localStorage.getItem("dados")) {
        this.dados = JSON.parse(localStorage.getItem("dados"));
      }
    }
  },
  methods: {
    validate() {
      // console.log(this.$route.params);
      /*if (this.$refs.form.validate()) {
                // this.snackbar = true
                this.signUp()
            }*/
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    signUp: function() {
      this.processing = true;
      if (this.email.trim() === "") {
        console.error("Erro: Email é obrigatório");
        return 0;
      }
      let user = {
        customer_id: this.email,
        email: this.email,
        name: this.name,
        state: this.state,
        phone: this.phone,
        promo_code: this.promo_code,
        average_consumption: this.average_consumption,
        step: this.step
      };

      addLead(user)
        .then(result => {
          if (result.data) {
            localStorage.setItem("lead", JSON.stringify(user));
            if (user.state.sigla === "MG") {
              this.processing = false;
              this.$router.replace("cadastro-conta-energia");
            } else {
              this.processing = false;
              const data = {
                email: user.email,
                name: user.name,
                dynamic_template_data: {
                  name: user.name
                },
                template_id: process.env.VUE_APP_SENDGRID_TEMPLATE_LISTAESPERA
              };
              enviarEmail(data)
                .then(this.$router.replace("lista-espera"))
                .catch(error => {
                  console.error("Error: Erro ao enviar email", error);
                  this.$router.replace("lista-espera");
                });
            }
          }
        })
        .catch(err => {
          this.processing = false;
          console.error("Error: ", err);
        });
    }
  }
};
</script>

<style>
@charset "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}

fieldset,
img {
  border: 0;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
caption,
th {
  text-align: left;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  padding: 0;
}
q:before,
q:after {
  content: "";
}
abbr,
acronym {
  border: 0;
}
* {
  -webkit-box-sizing: border-box; /* Safari<=5 Android<=3 */
  -moz-box-sizing: border-box; /* <=28 */
  box-sizing: border-box;
}

/* Custom
 * --------------------------------------- */
html,
body {
  height: 100%;
}
.header {
  padding-top: 40px;
  font-size: 1.5em;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  display: table-cell;
  color: rgb(51, 51, 51);
}
.header span {
  font-size: 1.5em;
  color: #fff;
  font-weight: bold;
}
.header h1 {
  font-size: 1.8em;
  color: #fff;
  font-weight: bold;
}
.header br {
  font-size: 3em;
  color: #fff;
  font-weight: bold;
}
.form_cadastro {
  height: 100%;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  background-color: #fff;
  padding: 10% 5% 2% 5%;
  text-align: center;
  border-radius: 10px;
}
.direita {
  width: 95%;
  padding-left: 5%;
  padding-top: 10%;
  text-align: left;
}

h3 {
  font-size: 4em;
  /* text-align: center; */
  color: #fff;
  font-weight: bold;
}
h2 {
  font-size: 2em;
  /* text-align: center; */
  color: #fff;
  font-weight: bold;
}
#logo {
  position: fixed;
  top: 20px;
  left: 20px;
  color: #fff;
  font-weight: bold;
  z-index: 99;
  font-size: 1.9em;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

/* Menu
 * --------------------------------------- */
#menu-line {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 159px;
  height: 2px;
  background: #fff;
}

#menu {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 70;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  letter-spacing: 1px;
  font-size: 1.1em;
}
#menu li {
  display: inline-block;
  margin: 10px 0px;
  position: relative;
}
#menu a {
  color: #fff;
  padding: 0 1.1em 1.1em 1.1em;
}
#menu li.active a:after {
  content: "";
  margin: 0 1.1em 0 1.1em;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  display: block;
}
.v-progress-circular {
  margin: 1rem;
}
</style>

